import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_alltomtraning/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {brand: { eq: "wod & done" }}) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				size
				length
				weight
			}
			fields {
				brand_url
				full_name
				full_name_url
				model_url
			}
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "wod--done-träningsutrustning"
    }}>{`Wod & Done Träningsutrustning`}</h1>
    <p>{`Välkommen till vår brand-sida för Wod & Done, en ledande tillverkare av träningsutrustning som erbjuder optimalt skydd och prestanda för dina händer under intensiva träningssessioner. Här hittar du information om olika serier från Wod & Done, deras unika egenskaper och en kort köpguide för att hjälpa dig välja rätt produkt för dina behov. Utforska våra Wod & Done serier och ta ditt träningspass till nästa nivå!`}</p>
    <h2 {...{
      "id": "skydda-dina-händer-med-wod--done"
    }}>{`Skydda dina händer med Wod & Done`}</h2>
    <p>{`Wod & Done är känt för sina innovativa och högkvalitativa handskydd som passar perfekt för högintensiva träningsformer som crossfit och gymnastikövningar. Med fokus på komfort och prestanda erbjuder Wod & Done gripprodukter som är både krit- och svetttåliga, vilket gör dem idealiska för både nybörjare och erfarna atleter. `}</p>
    <h2 {...{
      "id": "serier-från-wod--done"
    }}>{`Serier från Wod & Done`}</h2>
    <h3 {...{
      "id": "wod--done-hand-protection-wod--done-yellow"
    }}>{`Wod & Done Hand Protection (Wod & Done Yellow)`}</h3>
    <p><strong parentName="p">{`Wod & Done Hand Protection-serien`}</strong>{`, även känd som `}<strong parentName="p">{`Wod & Done Yellow`}</strong>{`, erbjuder de tunnaste och mest effektiva handskydden på marknaden. Dessa grips är tillverkade av ett högkvalitativt kinesiologiskt material som både är elastiskt och hållbart. Deras minimalistiska design säkerställer att de minimerar obehag och maximerar prestanda utan att störa dina rörelser.`}</p>
    <p>{`Nyckelfunktioner för Wod & Done Hand Protection:`}</p>
    <ul>
      <li parentName="ul">{`Tillverkade av elastiskt och hållbart kinesiologiskt material.`}</li>
      <li parentName="ul">{`Designade för att minimera obehag och maximera prestanda.`}</li>
      <li parentName="ul">{`Krit- och svetttåliga för högintensiva träningsformer.`}</li>
      <li parentName="ul">{`Perfekta för både nybörjare och erfarna atleter.`}</li>
    </ul>
    <h3 {...{
      "id": "wod--done-grips"
    }}>{`Wod & Done Grips`}</h3>
    <p><strong parentName="p">{`Wod & Done Grips-serien`}</strong>{` erbjuder en ny nivå av komfort och skydd under dina träningspass. Tillverkade med ett avancerat kinesiologiskt material, dessa grips erbjuder optimal prestanda utan att komma i vägen. De är engångs, krit- och svetttåliga och passar alla handstorlekar. Perfekt för den kräsne atleten som inte vill kompromissa med kvalitet och komfort.`}</p>
    <p>{`Nyckelfunktioner för Wod & Done Grips:`}</p>
    <ul>
      <li parentName="ul">{`Avancerat kinesiologiskt material för outtröttligt stöd och skydd.`}</li>
      <li parentName="ul">{`Optimerade för gymnastikövningar.`}</li>
      <li parentName="ul">{`Engångsanvändning för maximal hygien.`}</li>
      <li parentName="ul">{`Lämpliga för alla handstorlekar.`}</li>
    </ul>
    <h2 {...{
      "id": "köpguide-för-wod--done-träningsutrustning"
    }}>{`Köpguide för Wod & Done Träningsutrustning`}</h2>
    <p>{`Att välja rätt handskydd kan göra en enorm skillnad för din träningsprestation och komfort. Här är några tips för att hjälpa dig välja rätt serie från Wod & Done:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Träningsform`}</strong>{`: Om du tränar högintensiva träningsformer som crossfit, välj Wod & Done Hand Protection. Dessa grips är designade för att hantera de höga kraven från sådana träningspass.`}</li>
      <li parentName="ol"><strong parentName="li">{`Material`}</strong>{`: Båda serierna använder högkvalitativa kinesiologiska material, men om du föredrar engångsgrips, välj Wod & Done Grips.`}</li>
      <li parentName="ol"><strong parentName="li">{`Handstorlek`}</strong>{`: Wod & Done Grips är lämpliga för alla handstorlekar, medan Wod & Done Hand Protection är ergonomiskt utformade för att passa de flesta användare.`}</li>
      <li parentName="ol"><strong parentName="li">{`Komfort och Prestanda`}</strong>{`: För maximal komfort och oslagbar prestanda, rekommenderas Wod & Done Yellow, som är det tunna och slitstarka valet för seriösa atleter.`}</li>
    </ol>
    <p>{`Ta din träning till nästa nivå med Wod & Done träningsutrustning. Utforska vårt sortiment och hitta de handskydd som passar bäst för dina behov idag!`}</p>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      